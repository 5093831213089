import React, { useEffect } from 'react';

function Index(props) {
    useEffect(()=>{
        window.location.replace('/')
    },[])
    return (
        <div>
            
        </div>
    );
}

export default Index;